import React from 'react'
import { FormattedMessage } from 'react-intl'
import iconCalendar from '../../../assets/book-properties.svg'
import iconSearch from '../../../assets/find-accommodation.svg'
import iconManage from '../../../assets/manage-your-booking.svg'
import { Heading } from '../../../components'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import peopleMobile from '../../../assets/what-we-do-mobile.png'
import peopleDesktop from '../../../assets/what-we-do-desktop.png'
import css from './CustomWhatWeDo.module.css'

const details = [
    {
        icon: iconSearch,
        titleId: "CustomWhatWeDo.pointOneTitle",
        descId: "CustomWhatWeDo.pointOneDesc",
    },
    {
        icon: iconCalendar,
        titleId: "CustomWhatWeDo.pointTwoTitle",
        descId: "CustomWhatWeDo.pointTwoDesc",
    },
    {
        icon: iconManage,
        titleId: "CustomWhatWeDo.pointThreeTitle",
        descId: "CustomWhatWeDo.pointThreeDesc",
    },
];

const CustomWhatWeDo = () => {
    return (
        <SectionContainer className={css.customWhatWeDo}>
            <div className={css.contentContainer}>
                <div className={css.textContent}>
                    <Heading as="h2" className={css.title}>
                        <FormattedMessage id="CustomWhatWeDo.title" />
                    </Heading>
                    {details.map((detail, index) => (
                        <div key={index} className={css.detailBox}>
                            <img src={detail.icon} alt="" />
                            <div className={css.detailContent}>
                                <Heading as="h3" className={css.detailTitle}>
                                    <FormattedMessage id={detail.titleId} />
                                </Heading>
                                <p>
                                    <FormattedMessage id={detail.descId} />
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={css.imgBox}>
                    <img src={peopleMobile} alt='' style={{ width: '100%' }} className={css.peopleMobile} />
                    <img src={peopleDesktop} alt='' style={{ width: '478px' }} className={css.peopleDesktop} />
                </div>
            </div>
            <div className={css.theEllipse}></div>
        </SectionContainer>
    );
};

export default CustomWhatWeDo
import React from 'react'
import css from './CustomWhy.module.css'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import { Heading } from '../../../components'
import { FormattedMessage } from 'react-intl'
import iconDiscount from '../../../assets/icon-discount.svg'
import iconHome from '../../../assets/icon-home.svg'
import iconCertificate from '../../../assets/icon-certificate.svg'
import iconPayment from '../../../assets/icon-payment.svg'
import iconCustomerService from '../../../assets/icon-customer-service.svg'
import imgWhySection from '../../../assets/img-why-section.png'

const DetailBlock = ({ icon, titleId, descId }) => (
    <div className={css.detailBlock}>
        <img src={icon} alt="" />
        <div className={css.detailContent}>
            <Heading as="h3" className={css.detailTitle}>
                <FormattedMessage id={titleId} />
            </Heading>
            <p>
                <FormattedMessage id={descId} />
            </p>
        </div>
    </div>
);

const CustomWhy = () => {
    return (
        <SectionContainer className={css.customWhySection}>
            <div className={css.bgPurpose}></div>
            <div className={css.contentContainer}>
                <div className={css.sectionImg}>
                    <img src={imgWhySection} alt='this is image. will be vertically center' />
                </div>
                <div className={css.contentWrapper}>
                    <Heading as="h2" className={css.sectionTitle}>
                        <FormattedMessage id="CustomWhy.title" />
                    </Heading>
                    <div className={css.detailsContainer}>
                        <DetailBlock icon={iconDiscount} titleId="CustomWhy.pointOneTitle" descId="CustomWhy.pointOneDesc" />
                        <DetailBlock icon={iconHome} titleId="CustomWhy.pointTwoTitle" descId="CustomWhy.pointTwoDesc" />
                        <DetailBlock icon={iconCertificate} titleId="CustomWhy.pointThreeTitle" descId="CustomWhy.pointThreeDesc" />
                        <DetailBlock icon={iconPayment} titleId="CustomWhy.pointFourTitle" descId="CustomWhy.pointFourDesc" />
                        <DetailBlock icon={iconCustomerService} titleId="CustomWhy.pointFiveTitle" descId="CustomWhy.pointFiveDesc" />
                    </div>
                </div>
            </div>
        </SectionContainer>
    )
}

export default CustomWhy
import React from 'react'
import { FormattedMessage } from 'react-intl'
import iconProtection from '../../../assets/protection.svg'
import { Heading } from '../../../components'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import css from './CustomPaymentProtection.module.css'

const CustomPaymentProtection = () => {
    return (
        <SectionContainer className={css.payProtectionSection}>
            <div className={css.contentContainer}>
                <div className={css.leftColumn}>
                    <img src={iconProtection} />
                </div>
                <div className={css.rightColumn}>
                    <Heading as="h2" className={css.title}>
                        <FormattedMessage id="CustomPaymentProtectionPolicy.title" />
                    </Heading>
                    <p className={css.description}>
                        <FormattedMessage id="CustomPaymentProtectionPolicy.description" />
                    </p>
                </div>
            </div>
        </SectionContainer>
    )
}

export default CustomPaymentProtection
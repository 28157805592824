import React from 'react'
import css from './CustomCTA1.module.css'
import { Heading, NamedLink, PrimaryButtonInline, SecondaryButtonInline } from '../../../components'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import { FormattedMessage } from 'react-intl'

const CustomCTA1 = () => {
    return (
        <SectionContainer className={css.cta1Section}>
            {/* <div className={css.contentContainer}> */}
            <div className={css.textsWrapper}>
                <Heading as="h2" className={css.title}>
                    <FormattedMessage id="CustomCTA1Section.title" />
                </Heading>
                <p className={css.description}>
                    <FormattedMessage id="CustomCTA1Section.description" />
                </p>
            </div>
            <NamedLink name="NewListingPage">
                <PrimaryButtonInline className={css.btnStay}>Add Your Property/Properties</PrimaryButtonInline>
            </NamedLink>
            {/* </div> */}
        </SectionContainer>
    )
}

export default CustomCTA1
import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import CustomAccommodationForSection from './CustomAccommodationForSection/CustomAccommodationForSection';
import CustomFeaturedAccomm from './CustomFeaturedAccomm/CustomFeaturedAccomm';
import CustomHero from './CustomHero/CustomHero';
import CustomWhatWeDo from './CustomWhatWeDo/CustomWhatWeDo';
import CustomHow from './CustomHow/CustomHow';
import CustomWhy from './CustomWhy/CustomWhy';
import CustomPaymentProtection from './CustomPaymentProtection/CustomPaymentProtection';
import CustomCTA1 from './CustomCTA1/CustomCTA1';
import CustomLevelService from './CustomLevelService/CustomLevelService';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import CompWeWorkWith from '../../components/CustomComponents/CompWeWorkWith/CompWeWorkWith';
import GreenCTA from '../../components/CustomComponents/GreenCTA/GreenCTA';

const CustomLandingPageComponent = props => {
    const {
        scrollingDisabled,
        onManageDisableScrolling,
        listings,
        searchInProgress,
        searchListingsError,
    } = props;

    console.log('yeeeah', {
        listings,
        searchInProgress,
        searchListingsError
    });

    return (
        <Page scrollingDisabled={scrollingDisabled}>
            <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
                <CustomHero />
                <CompWeWorkWith />
                <CustomAccommodationForSection />
                <CustomWhatWeDo />
                <CustomFeaturedAccomm listings={listings} />
                <CustomHow />
                <CustomWhy />
                <CustomPaymentProtection />
                <CustomCTA1 />
                <CustomLevelService />
                <GreenCTA titleId="CustomLandingPage.GreenCTATitle" linkName="SearchPage" buttonTextId="CustomLandingPage.GreenCTABtnText" />
            </LayoutSingleColumn>
        </Page>

    );
};

const mapStateToProps = state => {
    const {
        currentPageResultIds,
        searchInProgress,
        searchListingsError,
    } = state.CustomLandingPage;
    const listings = getListingsById(state, currentPageResultIds);

    return {
        listings,
        scrollingDisabled: isScrollingDisabled(state),
        searchInProgress,
        searchListingsError,
    };
};

const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const CustomLandingPage = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CustomLandingPageComponent);

export default CustomLandingPage;

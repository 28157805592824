import React from 'react'
import { FormattedMessage } from 'react-intl'
import businessTravelImg from '../../../assets/business-travellers.jpg'
import contractorImg from '../../../assets/contractors.jpg'
import emergencyAccommImg from '../../../assets/Emergency-Accommodation.jpg'
import { Heading, NamedLink } from '../../../components'
import { div } from '../../PageBuilder/BlockBuilder'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import css from './CustomAccommodationForSection.module.css'

const accommodations = [
    {
        img: contractorImg,
        titleId: "CustomAccommodationFor.targetOneTitle",
        descriptionId: "CustomAccommodationFor.targetOneDescription",
    },
    {
        img: emergencyAccommImg,
        titleId: "CustomAccommodationFor.targetTwoTitle",
        descriptionId: "CustomAccommodationFor.targetTwoDescription",
    },
    {
        img: businessTravelImg,
        titleId: "CustomAccommodationFor.targetThreeTitle",
        descriptionId: "CustomAccommodationFor.targetThreeDescription",
    },
];

const AccommodationBlock = ({ img, titleId, descriptionId }) => (
    <div className={css.accommodationsBlock}>
        <div>
            <img src={img} alt="" />
            <Heading as="h3" className={css.accommTitle}>
                <FormattedMessage id={titleId} />
            </Heading>
            <p>
                <FormattedMessage id={descriptionId} />
            </p>
        </div>
        <NamedLink name="SearchPage" className={css.btnFind}>
            <FormattedMessage id="CustomLinkTextButton.text" />
        </NamedLink>
    </div>
);

const CustomAccommodationForSection = () => {
    return (
        <SectionContainer className={css.customAccommFor}>
            <div className={css.contentContainer}>
                <Heading as="h2" className={css.title}>
                    <FormattedMessage id="CustomAccommodationFor.title" />
                </Heading>
                <div className={css.accommodationContainer}>
                    {accommodations.map((item, index) => (
                        <AccommodationBlock key={index} {...item} />
                    ))}
                </div>
            </div>
        </SectionContainer>
    );
};

export default CustomAccommodationForSection
import React from 'react'
import { FormattedMessage } from 'react-intl'
import gravitasLogo from '../../../assets/Gravitas-logo.png'
import hsbcLogo from '../../../assets/hsbc.png'
import nhsLogo from '../../../assets/nhs-logo.png'
import trinityConstructionLogo from '../../../assets/Trinity-Construction-logo.png'
import SectionContainer from '../../../containers/PageBuilder/SectionBuilder/SectionContainer'
import { Heading } from '../../Heading/Heading'
import css from './CompWeWorkWith.module.css'

const CompWeWorkWith = () => {
    return (
        <SectionContainer className={css.compWeWorkWith}>
            <Heading as="h2" className={css.title}>
                <FormattedMessage id="CustomCompWeWork.title" />
            </Heading>
            <div className={css.logosWrapper}>
                <div className={css.logosBlock}>
                    <img src={hsbcLogo} />
                </div>
                <div className={css.logosBlock}>
                    <img src={nhsLogo} />
                </div>
                <div className={css.logosBlock}>
                    <img src={trinityConstructionLogo} />
                </div>
                <div className={css.logosBlock}>
                    <img src={gravitasLogo} />
                </div>
            </div>
        </SectionContainer>
    )
}

export default CompWeWorkWith
import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import {
  FieldCheckbox,
  FieldPhoneNumberInput,
  FieldTextInput,
  Form,
  SecondaryButton,
  ValidationError,
  IconSpinner,
  Button,
} from '../../../components';
import { sendEnquiryEmail } from '../../../util/api';
import css from './HeroForm.module.css';

const HeroForm = () => {
  return (
    <FinalForm
      onSubmit={async values => {
        try {
          await sendEnquiryEmail(values);
          alert('Email sent!');
          window.location.reload();
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('An error occurred while submitting the form.');
        }
      }}
      // onSubmit={values => {
      //   sendEnquiryEmail(values);
      // }}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          handleSubmit,
          invalid,
          pristine,
          submitting,
          updated,
          updateInProgress,
          values,
        } = formRenderProps;
        const required = value =>
          value ? undefined : 'This field is required';

        return (
          <Form
            onSubmit={handleSubmit}
            className={css.detailsForm}
            id="details-form"
          >
            <FieldTextInput
              type="text"
              className={css.fieldGroup}
              id="fName"
              name="fName"
              label="First Name*"
              placeholder="First Name"
              validate={required}
            />
            <FieldTextInput
              type="text"
              className={css.fieldGroup}
              id="lName"
              name="lName"
              label="Last Name*"
              placeholder="Last Name"
              validate={required}
            />
            <FieldTextInput
              type="email"
              className={css.fieldGroup}
              id="email"
              name="email"
              label="Email*"
              placeholder="Email"
              validate={required}
            />
            <FieldPhoneNumberInput
              className={css.fieldGroup}
              id="phoneNumber"
              name="phoneNumber"
              label="Phone number*"
              placeholder="Phone number"
              validate={required}
            />
            <FieldTextInput
              className={css.detailsTextArea}
              type="textarea"
              id="details"
              name="details"
              label="Details*"
              placeholder="Any specific details or request"
              validate={required}
            />
            <Field type="checkbox" name="consent-checkbox" validate={required}>
              {({ input, meta }) => (
                <div>
                  <FieldCheckbox
                    id="consent-checkbox"
                    name="consent-checkbox"
                    label="I consent to the storage of my data according to the Privacy Policy (GDPR)."
                    {...input}
                  />
                  <ValidationError fieldMeta={meta} />
                </div>
              )}
            </Field>
            <Button
              className={css.btnGetInTouch}
              type="submit"
              disabled={pristine || invalid || submitting}
            >
              {submitting ? (
                <p className={css.spinner}>
                <IconSpinner />
              </p>
                // <span className={css.loader}></span>
              ) : (
                'Get in touch'
              )}
            </Button>
          </Form>
        );
      }}
    ></FinalForm>
  );
};

export default HeroForm;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import css from './ListingPage.module.css';
import tickLogo from '../../assets/tick.png';

const SectionFacilitiesMaybe = ({ facilities }) => {
  if (!facilities || facilities.length === 0) {
    return null;
  }

  return (
    <section className={css.sectionFacilities}>
      <h2 className={css.facilitiesTitle}>
        <FormattedMessage id="ListingPage.facilitiesTitle" />
      </h2>
      <ul className={css.facilitiesList}>
        {facilities.map((facility, index) => (
          <li key={index} className={css.facilityItem}>
            <p className={css.facilityContainer}>
              <img src={tickLogo} className={css.tickLogo} />
              {facility}
            </p>
          </li>
        ))}
      </ul>
    </section>
  );
};

SectionFacilitiesMaybe.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.string),
};

export default SectionFacilitiesMaybe;

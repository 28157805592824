import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { getBulkUploadListings, postBulkUploadListings } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/BulkUploadPage/SET_INITIAL_STATE';

export const BULK_UPLOAD_REQUEST = 'app/BulkUploadPage/BULK_UPLOAD_REQUEST';
export const BULK_UPLOAD_SUCCESS = 'app/BulkUploadPage/BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_ERROR = 'app/BulkUploadPage/BULK_UPLOAD_ERROR';

// ================ Reducer ================ //

const initialState = {
  userListings: [],
  bulkUploadError: null,
};

export default function bulkUploadReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case BULK_UPLOAD_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        uploadedListings: [],
        bulkUploadError: null,
      };
    case BULK_UPLOAD_SUCCESS:
      return { ...state, uploadedListings: payload.listings };
    case BULK_UPLOAD_ERROR:
      return { ...state, uploadedListings: [], bulkUploadError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const bulkUploadRequest = userId => ({
  type: BULK_UPLOAD_REQUEST,
  payload: {},
});

export const bulkUploadSuccess = listings => ({
  type: BULK_UPLOAD_SUCCESS,
  payload: { listings },
});

export const bulkUploadError = e => ({
  type: BULK_UPLOAD_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const bulkUploadListings = params => async (dispatch, getState, sdk) => {
  dispatch(bulkUploadRequest());
  try {
    await postBulkUploadListings(params);
    dispatch(bulkUploadSuccess());
    return true;
  } catch (err) {
    dispatch(bulkUploadError(storableError(err)));
    return false;
  }
};

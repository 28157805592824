import React from 'react'
import { FormattedMessage } from 'react-intl'
import iconListingSorted from '../../../assets/icon-listing-sorted.svg'
import iconListing from '../../../assets/icon-listing.svg'
import iconRequirement from '../../../assets/icon-requirement.svg'
import { Heading } from '../../../components'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import css from './CustomHow.module.css'

const DetailBlock = ({ icon, titleId, descId }) => {
    return (
        <div className={css.detailBlock}>
            <img src={icon} alt="" className={css.icon} />
            <h3 className={css.detailTitle}>
                <FormattedMessage id={titleId} />
            </h3>
            <p>
                <FormattedMessage id={descId} />
            </p>
        </div>
    );
};

const CustomHow = () => {
    return (
        <SectionContainer className={css.customHowSection}>
            <div className={css.contentContainer}>
                <div className={css.headingContainer}>
                    <Heading as="h2" className={css.sectionTitle}>
                        <FormattedMessage id="CustomHowItWorks.title" />
                    </Heading>
                </div>
                <div className={css.detailsContainer}>
                    <DetailBlock
                        icon={iconRequirement}
                        titleId="CustomHowItWorks.titleOne"
                        descId="CustomHowItWorks.descOne"
                    />
                    <DetailBlock
                        icon={iconListing}
                        titleId="CustomHowItWorks.titleTwo"
                        descId="CustomHowItWorks.descTwo"
                    />
                    <DetailBlock
                        icon={iconListingSorted}
                        titleId="CustomHowItWorks.titleThree"
                        descId="CustomHowItWorks.descThree"
                    />
                </div>
            </div>
        </SectionContainer>
    )
}

export default CustomHow
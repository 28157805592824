import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Heading, NamedLink, PrimaryButton, ListingCard } from '../../../components'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import css from './CustomFeaturedAccomm.module.css'

const CustomFeaturedAccomm = (props) => {
    const { listings = [] } = props;
    return (
        <SectionContainer className={css.customFeaturedAccomm}>
            <div className={css.contentContainer}>
                <div className={css.headingContainer}>
                    <Heading as="h2" className={css.sectionTitle}>
                        <FormattedMessage id="CustomFeaturedAccommSection.title" />
                    </Heading>
                    <p>
                        <FormattedMessage id="CustomFeaturedAccommSection.description" />
                    </p>
                </div>
                <div className={css.listingsWrapper}>
                    {listings.map((listing, i) => {
                        return <ListingCard key={i} listing={listing} className={css.theListing} />
                    })}
                </div>
                <NamedLink name="SearchPage" className={css.linkBtn}>
                    <PrimaryButton className={css.customButton}>View more accommodations</PrimaryButton>
                </NamedLink>
            </div>
        </SectionContainer>
    )
}

export default CustomFeaturedAccomm
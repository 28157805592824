import React from 'react'
import { FormattedMessage } from 'react-intl'
import iconPhone from '../../../assets/icon-phone.svg'
import { Heading, NamedLink, PrimaryButton } from '../../../components'
import HeroForm from '../../../components/CustomComponents/HeroForm/HeroForm'
import * as validators from '../../../util/validators'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import bgVid from '../../../assets/hero-video.mp4'
import css from './CustomHero.module.css'

const CustomHero = () => {
    const required = validators.required('This field is required');
    return (
        <SectionContainer className={css.customHeroSection}>

            <div className={css.contentContainer}>
                <div className={css.contentWrapper}>
                    <div className={css.leftColumn}>
                        <div className={css.textsWrapper}>
                            <Heading as="h1" className={css.textTitle}>
                                Find Premium Accommodation For 30 Days Or More.<br /><span style={{ color: 'var(--colorPrimaryButton)' }}>At Discount Prices.</span>
                            </Heading>
                            <p className={css.textDesc}>
                                <FormattedMessage id="CustomHeroSection.description" />
                            </p>
                        </div>

                        <div className={css.statisticsWrapper}>
                            <div>
                                <p className={css.textLarge}>
                                    <FormattedMessage id="CustomHeroSection.propertiesNum" />
                                </p>
                                <p className={css.textSmall}>
                                    <FormattedMessage id="CustomHeroSection.propertiesText" />
                                </p>
                            </div>
                            <div>
                                <p className={css.textLarge}>
                                    <FormattedMessage id="CustomHeroSection.accommNum" />
                                </p>
                                <p className={css.textSmall}>
                                    <FormattedMessage id="CustomHeroSection.accommText" />
                                </p>
                            </div>
                            <div>
                                <p className={css.textLarge}>
                                    <FormattedMessage id="CustomHeroSection.savingNum" />
                                </p>
                                <p className={css.textSmall}>
                                    <FormattedMessage id="CustomHeroSection.savingText" />
                                </p>
                            </div>
                        </div>
                        <NamedLink name='SearchPage' className={css.linkBtn}>
                            <PrimaryButton className={css.customButton}>Find Accommodation</PrimaryButton>
                        </NamedLink>
                    </div>
                    <div className={css.rightColumn}>
                        <div className={css.formContainer}>
                            <img src={iconPhone} />
                            <div>
                                <p>
                                    <FormattedMessage id="CustomHeroSection.contactText" />
                                </p>
                                <p style={{ color: 'var(--colorPrimaryButton)', fontSize: '20px', fontWeight: 600 }}>
                                    <FormattedMessage id="CustomHeroSection.contactPhone" />
                                </p>
                            </div>
                        </div>
                        <HeroForm />
                    </div>
                </div>
                <video
                    className={css.bgVideo}
                    src={bgVid}
                    type="video/mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                />
            </div>
        </SectionContainer>
    )
}

export default CustomHero
import React from 'react'
import { FormattedMessage } from 'react-intl'
import SectionContainer from '../../../containers/PageBuilder/SectionBuilder/SectionContainer'
import { PrimaryButtonInline } from '../../Button/Button'
import { Heading } from '../../Heading/Heading'
import NamedLink from '../../NamedLink/NamedLink'
import css from './GreenCTA.module.css'

const GreenCTA = ({ titleId, linkName, buttonTextId }) => {
    return (
        <SectionContainer className={css.greenCTA}>
            <Heading as="h2" className={css.title}>
                <FormattedMessage id={titleId} />
            </Heading>
            <NamedLink name={linkName}>
                <PrimaryButtonInline className={css.theBtn}>
                    <FormattedMessage id={buttonTextId} />
                </PrimaryButtonInline>
            </NamedLink>
        </SectionContainer>
    )
}

export default GreenCTA
import React from 'react'
import { FormattedMessage } from 'react-intl'
import iconCleaning from '../../../assets/icon-cleaning.svg'
import iconLinen from '../../../assets/icon-linen.svg'
import iconTv from '../../../assets/icon-tv.svg'
import imgCleaning from '../../../assets/img-cleaning.jpg'
import imgHomeComfort from '../../../assets/img-home-comfort.png'
import imgLinen from '../../../assets/img-linen.jpg'
import { Heading } from '../../../components'
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer'
import css from './CustomLevelService.module.css'

const details = [
    {
        icon: iconCleaning,
        titleId: "CustomLevelService.titleOne",
        descId: "CustomLevelService.descOne",
        image: imgCleaning,
    },
    {
        icon: iconLinen,
        titleId: "CustomLevelService.titleTwo",
        descId: "CustomLevelService.descTwo",
        image: imgLinen,
    },
    {
        icon: iconTv,
        titleId: "CustomLevelService.titleThree",
        descId: "CustomLevelService.descThree",
        image: imgHomeComfort,
    },
];

const CustomLevelService = () => {
    return (
        <SectionContainer className={css.levelServiceSection}>
            <div className={css.contentContainer}>
                <div className={css.textsWrapper}>
                    <Heading as="h2" className={css.title}>
                        <FormattedMessage id="CustomLevelService.title" />
                    </Heading>
                    <p>
                        <FormattedMessage id="CustomLevelService.description" />
                    </p>
                </div>
                <div className={css.detailsContainer}>
                    {details.map((detail, index) => (
                        <div key={index} className={css.detailsBlock}>
                            <div>
                                <img src={detail.icon} alt="" className={css.icons} />
                                <Heading as="h3" className={css.accommTitle}>
                                    <FormattedMessage id={detail.titleId} />
                                </Heading>
                                <p>
                                    <FormattedMessage id={detail.descId} />
                                </p>
                            </div>
                            <img src={detail.image} alt="" className={css.detailImage} />
                        </div>
                    ))}
                </div>
            </div>
        </SectionContainer>
    );
};

export default CustomLevelService
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { sendBookingEmail } from '../../../util/api';

import {
  FormattedMessage,
  intlShape,
  injectIntl,
} from '../../../util/reactIntl';
import {
  required,
  bookingDatesRequired,
  composeValidators,
} from '../../../util/validators';

import {
  Form,
  PrimaryButton,
  FieldDateRangePicker,
  H6,
  FieldTextInput,
  IconSpinner,
} from '../../../components';

import css from './EmailBookingForm.module.css';
const minimumDaysRange = (days, message) => value => {
  const startDate = value && value.startDate;
  const endDate = value && value.endDate;

  if (startDate && endDate) {
    const diffInTime = endDate.getTime() - startDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    if (diffInDays < days) {
      return message;
    }
  }
  return undefined;
};
const EmailBookingForm = props => {
  const { intl, listingTitle } = props;
  const currentUrl = window.location.href;
  const [submissionStatus, setSubmissionStatus] = useState('');

  return (
    <div>
      <FinalForm
        // onSubmit={values => {
        //   const finalValues = { ...values, currentUrl, listingTitle };
        //   sendBookingEmail(finalValues);
        // }}
        onSubmit={async (values, form) => {
          try {
            const finalValues = { ...values, currentUrl, listingTitle };
            await sendBookingEmail(finalValues);
            setSubmissionStatus('success');
            setTimeout(() => {
              form.reset(); // Reset the form after a short delay
              setSubmissionStatus(''); // Clear submission status after reset
            }, 500);
          } catch (error) {
            console.error('Error submitting form:', error);
            setSubmissionStatus('error');
          }
        }}
        render={formRenderProps => {
          const { handleSubmit, values, submitting } = formRenderProps;

          return (
            <Form onSubmit={handleSubmit} className={css.root}>
              <div className={css.nameFields}>
                <FieldTextInput
                  className={css.input}
                  type="text"
                  name="firstName"
                  id="firstName"
                  label={intl.formatMessage({
                    id: 'EmailBookingForm.firstName',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'EmailBookingForm.firstNamePlaceholder',
                  })}
                  validate={required('Field required')}
                />
                <FieldTextInput
                  className={css.input}
                  type="text"
                  name="lastName"
                  id="lastName"
                  label={intl.formatMessage({
                    id: 'EmailBookingForm.lastName',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'EmailBookingForm.lastNamePlaceholder',
                  })}
                  validate={required('Field required')}
                />
              </div>

              <FieldTextInput
                className={css.input}
                type="text"
                name="location"
                id="location"
                label={intl.formatMessage({
                  id: 'EmailBookingForm.location',
                })}
                placeholder={intl.formatMessage({
                  id: 'EmailBookingForm.locationPlaceholder',
                })}
                validate={required('Field required')}
              />

              <FieldDateRangePicker
                className={css.input}
                startDateId={`bookingStartDate`}
                startDateLabel={intl.formatMessage({
                  id: 'EmailBookingForm.bookingStartTitle',
                })}
                startDatePlaceholderText={intl.formatMessage({
                  id: 'EmailBookingForm.startDatePlaceholderText',
                })}
                endDateId={`bookingEndDate`}
                endDateLabel={intl.formatMessage({
                  id: 'EmailBookingForm.bookingEndTitle',
                })}
                endDatePlaceholderText={intl.formatMessage({
                  id: 'EmailBookingForm.endDatePlaceholderText',
                })}
                name="bookingDates"
                validate={composeValidators(
                  // required('Field required'),
                  // bookingDatesRequired('Booking dates required'),
                  minimumDaysRange(30, 'Minimum stay is 30 days')
                )}
              />
              <FieldTextInput
                className={css.input}
                type="number"
                name="contact"
                id="contact"
                label={intl.formatMessage({
                  id: 'EmailBookingForm.contact',
                })}
                placeholder={intl.formatMessage({
                  id: 'EmailBookingForm.contactPlaceholder',
                })}
                validate={required('Field required')}
              />

              <FieldTextInput
                className={css.input}
                type="email"
                name="email"
                id="email"
                label={intl.formatMessage({
                  id: 'EmailBookingForm.email',
                })}
                placeholder={intl.formatMessage({
                  id: 'EmailBookingForm.emailPlaceholder',
                })}
                validate={required('Field required')}
              />

              <FieldTextInput
                className={css.input}
                type="number"
                name="guest"
                id="guest"
                label={intl.formatMessage({
                  id: 'EmailBookingForm.guest',
                })}
                placeholder={intl.formatMessage({
                  id: 'EmailBookingForm.guestPlaceholder',
                })}
                validate={required('Field required')}
              />
              <FieldTextInput
                className={css.input}
                type="number"
                name="parking"
                id="parking"
                label={intl.formatMessage({
                  id: 'EmailBookingForm.parking',
                })}
                placeholder={intl.formatMessage({
                  id: 'EmailBookingForm.parkingPlaceholder',
                })}
              />
              <FieldTextInput
                className={css.input}
                type="number"
                name="pets"
                id="pets"
                label={intl.formatMessage({
                  id: 'EmailBookingForm.pets',
                })}
                placeholder={intl.formatMessage({
                  id: 'EmailBookingForm.petsPlaceholder',
                })}
              />
              <div className={css.submitButton}>
                <PrimaryButton
                  type="submit"
                  loading={submitting}
                  disabled={formRenderProps.invalid || formRenderProps.pristine}
                >
                  {submitting ? (
                    <p className={css.spinner}>
                      <IconSpinner />
                    </p>
                  ) : submissionStatus === 'success' ? (
                    'Email sent!'
                  ) : (
                    <FormattedMessage id="EmailBookingForm.submit" />
                  )}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};
export default compose(injectIntl)(EmailBookingForm);
